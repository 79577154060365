export const cn = {
    signIn: '登录',
    username: '用户名',
    password: '密码',
    tokenSignin: '使用token登录',
    nickname: '昵称',
    signUp: '注册',
    registrationFailed:'注册失败',
    signUpSuccessfully: '注册成功',
    loginSuccessfully: '登录成功',
    logoutSuccessfully: '退出登录成功',
    notEmpty: '不能为空',
    request: '请求添加好友',
    add: '添加',
    delete: '删除',
    modify: '修改',
    confirm: '确认操作：',
    addAFriend: '添加好友',
    delAFriend: '删除好友',
    quit: '退出',
    groups: '群组',
    strangers: '陌生人',
    chatrooms: '聊天室',
    friends: '好友',
    block: '加入黑名单',
    blacklist: '黑名单',
    invite: '邀请',
    close: '关闭',
    message: '消息',
    exceed: '请上传大小不超过10M的文件',
    invalidType: '不支持此类型',
    uploadFileFailed: '上传文件发生错误',
    join: '加入',
    agree: '同意',
    reject: '拒绝',
    send: '发送',
    notin: '您不在当前聊天室',
    sendImageFailed: '图片发送失败',
    sendAudioFailed: '发送音频失败',
    sendFileFailed: '文件音频失败',
    image: '图片',
    audio: '音频',
    file: '文件',
    location: '位置',
    video: '视频',
    cmd: '命令消息',
    download: '点击下载',
    noaccount: '没有账号?',
    signupnow: '现在注册',
    haveaccount: '已有账号?',
    you: '你',
    admin: '管理员',
    dismissed: '已被踢出',
    LeaveGroup: '离开群组：',
    muted: '已被禁言',
    // 订阅
    refuse: '请求被拒绝',
    subscribed: '已订阅',
    unsubscribed: '已退订',
    read: '已读',
    unread: '未读',
    // 群组
    createGroup: '创建群组',
    groupType: '群组类型',
    private: '私有群',
    public: '公有群',
    permission: '加群权限',
    approvalTrue: '审批',
    approvalFalse: '随便加',
    next: '下一步',
    back: '上一步',
    joinGroup: '申请入群',
    groupInfo: '群信息',
    groupName: '群组名称',
    groupNameWaring: '请输入群组名称！',
    inviteToGroup: '邀请群成员',
    inviteIntoGroup: '邀请你加入群组：',
    groupRequest: '加群申请',
    groupInvite: '加群邀请',
    modifyGroupInfo: '修改群信息',
    groupBlacklist: '群组黑名单',
    removeFromGroupBlackList: '移出黑名单',
    dissovleGroup: '解散群组',
    quitGroup: '退出群组',
    members: '群组成员',
    setAdmin: '设为管理员',
    removeAdmin: '移除管理员',
    mute: '禁言',
    groupBlockSingle: '加入群黑名单',
    removeSingleGroupMember: '从本群移除',
    descriptionWaring: '请输入群组简介！',
    description: '简介',
    // 陌生人
    addFriend: '加为好友',
    addFriendMessage: '添加好友申请已发出,等待对方同意',
    // 搜索
    search: '搜索',
    // 系统
    apply: '申请',
    offline: '离线',
    group: '群组',
    chatroom: '聊天室',
    LoadTimeout: '加载超时！',
    loadFailded: '错误：加载失败！',
    successfully: '成功',
    failed: '失败',
    by: '，操作人：',
    sentFailed: '未送达',
    serverSideCloseWebsocketConnection: '服务端已关闭 websocket 链接',
    webIMConnectionAuthError: 'WebIM 链接鉴权失败',
    offlineByMultiLogin: '多重登录，已离线',
    noMoreMessage: '没有更多消息啦~',
    already: '已经',
    in: '在',
    id: 'ID',
    notExist: '不存在',
    needApproval: '需要审批',
    empty: '空',
    back: '后退',
    //webrtc
    requestVideo: ' 请求视频通话...',
    requestVoice: ' 请求语音通话...',
    waiting: '等待对方接受邀请',
    waitingVideo: ' 视频中...',
    waitingVoice: ' 语音中...',
}
